import React from 'react'
import { Helmet } from 'react-helmet'
import { Parallax } from 'react-scroll-parallax'
import Shape1 from '../assets/what-is-couchbase/Shape-1.png'
import Shape2 from '../assets/what-is-couchbase/Shape-2.png'
import Shape3 from '../assets/what-is-couchbase/Shape-3.png'
import Shape4 from '../assets/what-is-couchbase/Shape-4.png'
import sqlIcon from '../assets/what-is-couchbase/sql-icon.svg'
import ftsIcon from '../assets/what-is-couchbase/fts-icon.svg'
import eventingIcon from '../assets/what-is-couchbase/eventing-icon.svg'
import kvIcon from '../assets/what-is-couchbase/keyvalue-icon.svg'
import analyticsIcon from '../assets/what-is-couchbase/analytics-icon.svg'
import xdcrIcon from '../assets/what-is-couchbase/xdcr-icon.svg'
import capellaIcon from '../assets/what-is-couchbase/capella-icon.png'
import serverIcon from '../assets/what-is-couchbase/server-icone.png'
import mobileIcon from '../assets/what-is-couchbase/mobile-icon.png'
import operatorIcon from '../assets/what-is-couchbase/autonomous-operator-icon.png'
import faqImage from '../assets/what-is-couchbase/FAQ-graphic.png'
import capellaVideoThumb from '../assets/what-is-couchbase/Capella-video.png'
import wheelGraphic from '../assets/what-is-couchbase/wheel.png'

import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel, AccordionItemState,
} from 'react-accessible-accordion'

const FAQs = [
  {
    title: 'What are the benefits of a NoSQL database?',
    body: 'Flexibility, performance, and scalability.',
    plainBody: 'Flexibility, performance, and scalability.',
  },
  {
    title: 'What use cases does Couchbase excel at?',
    body: 'Couchbase is ideal for a wide and <a href="https://www.couchbase.com/customers" target="_blank">growing variety of use cases</a>, including user profile, session management, catalogs, content management, and financial tech.',
    plainBody: 'Couchbase is ideal for a wide and growing variety of use cases, including user profile, session management, catalogs, content management, and financial tech.',
  },
  {
    title: 'Does Couchbase have a DBaaS offering?',
    body: 'Yes, <a href="https://cloud.couchbase.com/sign-up" target="_blank">Couchbase Capella</a>.',
    plainBody: 'Yes, Couchbase Capella',
  },
  {
    title: 'What platforms is Couchbase available on?',
    body: 'Couchbase Capella can deploy clusters to <a href="https://docs.couchbase.com/cloud/reference/aws.html" target="_blank">AWS</a>, <a href="https://docs.couchbase.com/cloud/reference/gcp.html" target="_blank">GCP</a>, and (soon) Microsoft Azure. Couchbase Server can run on <a href="https://docs.couchbase.com/server/current/install/install-platforms.html" target="_blank">Windows</a>, <a href="https://docs.couchbase.com/server/current/install/install-platforms.html" target="_blank">Linux</a>, <a href="https://docs.couchbase.com/server/current/install/getting-started-docker.html" target="_blank">Docker</a>, <a href="https://docs.couchbase.com/operator/current/overview.html" target="_blank">Kubernetes</a>, and even <a href="https://docs.couchbase.com/server/current/install/install-platforms.html#supported-ARM-platforms" target="_blank">ARM platforms</a>. Couchbase Lite can run on iOS, Android, Xamarin, Java, and even embedded devices with C.',
    plainBody: 'Couchbase Capella can deploy clusters to AWS, GCP, and (soon) Microsoft Azure. Couchbase Server can run onWindows, Linux, Docker, Kubernetes, and even ARM platforms. Couchbase Lite can run on iOS, Android, Xamarin, Java, and even embedded devices with C.',
  },
  {
    title: 'What languages/frameworks/SDKs are available for Couchbase.',
    body: 'For <a href="https://docs.couchbase.com/home/sdk.html" target="_blank">Capella and Couchbase Server, SDKs are available</a> for .NET, C, Go, Java, Kotlin, Node.js, PHP, Python, Ruby, Scala, and Rust. For <a href="https://docs.couchbase.com/couchbase-lite/current/index.html" target="_blank">Couchbase Lite, SDKs are available</a> for Android, C, .NET, Java, JavaScript, Objective-C, and Swift. There are many libraries and integrations available (e.g., Spring Data, ASP.NET, Linq, Ottoman.js, and <a href="/https://developer.couchbase.com/" target="_blank">more</a>).',
    plainBody: 'For Capella and Couchbase Server, SDKs are available for .NET, C, Go, Java, Kotlin, Node.js, PHP, Python, Ruby, Scala, and Rust. For Couchbase Lite, SDKs are available for Android, C, .NET, Java, JavaScript, Objective-C, and Swift. There are many libraries and integrations available (e.g., Spring Data, ASP.NET, Linq, Ottoman.js, and more).',
  },
  {
    title: 'How does Couchbase compare to relational databases?',
    body: 'NoSQL databases access and store data <a href="https://developer.couchbase.com/comparing-document-vs-relational/" target="_blank">differently than relational databases</a>. However, Couchbase supports many of the best features of relational databases, including SQL and ACID.',
    plainBody: 'NoSQL databases access and store data differently than relational databases. However, Couchbase supports many of the best features of relational databases, including SQL and ACID.',
  },
  {
    title: 'How does Couchbase compare to other NoSQL databases?',
    body: 'Couchbase shares some things in common with other NoSQL databases, but Couchbase also has a variety of features and services that <a href="https://www.couchbase.com/nosql-database-cloud-comparison" target="_blank">most NoSQL competitors can\'t match</a>. These include a memory-first, shared-nothing architecture (built-in caching), full SQL capabilities, offline-first mobile capabilities, and more.',
    plainBody: 'Couchbase shares some things in common with other NoSQL databases, but Couchbase also has a variety of features and services that most NoSQL competitors can\'t match. These include a memory-first, shared-nothing architecture (built-in caching), full SQL capabilities, offline-first mobile capabilities, and more.',
  },
  {
    title: 'Is Couchbase just a commercial version of CouchDB?',
    body: 'Despite the similar names and some shared history long ago, <a href="https://www.couchbase.com/comparing-couchbase-vs-couchdb" target="_blank">Couchbase and Apache CouchDB are two different technologies</a>. Couchbase is not a fork of CouchDB (and vice versa), and they don’t have compatible APIs.',
    plainBody: 'Despite the similar names and some shared history long ago, Couchbase and Apache CouchDB are two different technologies. Couchbase is not a fork of CouchDB (and vice versa), and they don’t have compatible APIs.',
  },
]

const structuredJSON = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity":
    FAQs.map((element) => {
      return {
        "@type": "Question",
        "name": element.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": element.plainBody,
        },
      }
    }),
}

const WhatIsCouchbase = () => {
  return (
    <div className='page--what-is-couchbase max-w-full overflow-x-hidden'>
      <Helmet title={`What Is Couchbase | Couchbase Developer Portal`}>
        <meta name='description'
              content='Couchbase is a JSON database that serves the needs of application developers by reducing data sprawl, improving security, and lowering costs.' />
        <script className='structured-data-list'
                type='application/ld+json'>{`${JSON.stringify(structuredJSON)}`}</script>
      </Helmet>
      {/* HERO */}
      <div className='wrapper pt-12 sm:pt-40 pb-8 sm:pb-40 place-items-center z-10'>
        <div className='z-20'>
          <div className='order-last sm:order-first z-20'>
            <h1>What Is Couchbase?</h1>
            <p>
              Couchbase is a NoSQL distributed document database (JSON) with many of the best features of a relational
              DBMS: SQL, distributed ACID transactions, and much more.&nbsp;
              <a
                href='https://cloud.couchbase.com/sign-up'
                target='_blank'
                rel='noreferrer'>
                Couchbase Capella
              </a>
              ™&nbsp;is the easiest way to get
              started, but you can also download and run <a href='http://couchbase.com/downloads' target='_blank'
                                                            rel='noreferrer'>Couchbase
              Server</a> on-premises.
            </p>
          </div>
        </div>
      </div>


      <div className='bg-gray-230 relative -z-10 mb-0 md:mb-10'>
        <Parallax translateY={[-20, -40]} className='absolute -z-10 w-1/2 lg:w-1/3 -right-36 sm:-right-42 md:-right-56' easing={'easeInOutCubic'}>
          <img src={Shape4} alt='' className={'w-full fade-in'} />
        </Parallax>
        <Parallax translateY={[-80, -180]} className='absolute -z-10 w-1/2 lg:w-1/4 -right-60'
                  easing={'easeInOutCubic'}>
          <img src={Shape2} alt='' className={'w-full fade-in'} />
        </Parallax>
        <Parallax translateY={[-170, -180]} className='absolute -z-10 w-1/2 lg:w-1/4 mt-auto 2xl:mt-28'
                  style={{ marginLeft: "30%" }} easing={'easeInOutCubic'}>
          <img src={Shape3} alt='' className={'w-full mt-16 sm:mt-auto lg:mt-36 fade-in'} />
        </Parallax>
        <Parallax translateY={[-20, -100]} className='absolute -z-10 w-1/2 sm:w-1/4' easing={'easeInOutCubic'}>
          <img src={Shape1} alt='' className={'-ml-32 w-full fade-in'} />
        </Parallax>
        <div className='py-12 wrapper mx-auto text-left'>
          <div
            className='pt-12 sm:pt-20 pb-8 sm:pb-24 grid grid-cols-1 sm:grid-cols-2 place-items-center'>
            <div>
              <h2>What does Couchbase do?</h2>
              <p>
                Couchbase serves the modern needs of application developers by providing the greatest strengths of SQL,
                NoSQL, and NewSQL in a single database. Couchbase is built for microservices and for serverless,
                consumption-based computing on the cloud, delivering edge computing for mobile and IoT devices that are
                connected only occasionally or locally.
              </p>
              <p>
                Couchbase’s distributed architecture is designed to avoid a single point of failure. This enables
                elastic scaling, workload isolation, and real-time data replication for high availability, global
                geo-distribution, and disaster recovery.
              </p>
              <ul className='text-sm sm:text-base'>
                <li>A flexible database to store JSON data without a rigid schema</li>
                <li>A familiar SQL standard for queries on your JSON data</li>
                <li>The speed of a built-in managed cache, providing microsecond latency</li>
                <li>Scale that can accommodate a startup and grow to support a massive enterprise</li>
              </ul>
            </div>
            <div className='ml-4'>
              <img src={wheelGraphic} alt='' />
            </div>
          </div>
        </div>
      </div>

      <div className='py-12 wrapper text-left mb-0 md:mb-6'>
        <h2 className='text-center'>Couchbase Overview</h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-12 mt-5 sm:mt-8 w-11/12 sm:w-full mx-auto'>
          <OverviewCard
            icon={capellaIcon}
            title='Couchbase Capella'
            desc="Capella is a fully managed cloud Database-as-a-Service (DBaaS). It's the easiest and fastest way to get started with Couchbase."
            link1Text='About Capella'
            link1Dest={'https://www.couchbase.com/products/capella'}
            link2Text='Try it for free'
            link2Dest={'https://cloud.couchbase.com/sign-up'}
          />

          <OverviewCard
            icon={serverIcon}
            title='Couchbase Server'
            desc='Couchbase Server can be downloaded and installed anywhere, including Windows, Linux, and Mac.'
            link1Text='About Couchbase Server'
            link1Dest={'https://www.couchbase.com/products/server'}
            link2Text='Download Server'
            link2Dest={'https://www.couchbase.com/downloads'}
          />

          <OverviewCard
            icon={mobileIcon}
            title='Couchbase Mobile'
            desc="Couchbase Lite runs as a standalone embedded database on mobile, desktop, and edge devices and is capable of reading/writing data even while offline. Data can be synchronized automatically with Sync Gateway or <a href='https://www.couchbase.com/products/capella/app-services' target='_blank'>Capella App Services</a>."
            link1Text='About Couchbase Mobile'
            link1Dest={'https://www.couchbase.com/products/mobile'}
            link2Text='Download Mobile'
            link2Dest={'https://www.couchbase.com/downloads'}
          />

          <OverviewCard
            icon={operatorIcon}
            title='Autonomous Operator'
            desc='Couchbase runs more easily within Kubernetes when you use the official Couchbase Autonomous Operator. This operator provides a Couchbase-specific API for managing your database clusters.'
            link1Text='About Autonomous Operator'
            link1Dest={'https://www.couchbase.com/products/cloud/kubernetes'}
            link2Text='Download Autonomous Operator'
            link2Dest={'https://www.couchbase.com/downloads'}
          />


        </div>
      </div>


      <div className='bg-white'>
        <div className='py-12 wrapper mx-auto text-center mb-0 md:mb-6'>
          <h2>Couchbase Features</h2>
          <p className='w-11/12 mx-auto'>Couchbase is a multi-model database that supports capabilities including
            key-value, SQL++, full-text
            search, analytics, eventing, cross data center replication, and more.
          </p>
          <div className='text-left mt-14 w-11/12 sm:w-full mx-auto'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-12'>
              <div>
                <FeatureBlock
                  icon={sqlIcon}
                  title='SQL++'
                  desc='Access NoSQL JSON data with a full implementation of the familiar and popular SQL syntax (yes, this includes JOINs!).'
                  linkText='About SQL++'
                  linkDest='https://www.couchbase.com/products/n1ql'
                />
              </div>
              <div>
                <FeatureBlock
                  icon={kvIcon}
                  title='Key-value'
                  desc='Look up data as efficiently as possible with a direct key lookup.'
                  linkText='About key-value'
                  linkDest='https://docs.couchbase.com/server/current/learn/data/data.html'
                />
              </div>
              <div>
                <FeatureBlock
                  icon={ftsIcon}
                  title='Full-text search'
                  desc='Add a real-time search engine-like index to your data without copying or moving it into another tool.'
                  linkText='About full-text search'
                  linkDest='https://www.couchbase.com/products/full-text-search'
                />
              </div>
              <div>
                <FeatureBlock
                  icon={analyticsIcon}
                  title='Analytics'
                  desc='Perform complex BI and analytics queries on your data without impacting performance. Includes a full SQL implementation.'
                  linkText='About analytics'
                  linkDest='https://www.couchbase.com/products/analytics'
                />
              </div>
              <div>
                <FeatureBlock
                  icon={eventingIcon}
                  title='Eventing'
                  desc='Use the Eventing service to execute custom code whenever data is modified.'
                  linkText='About eventing'
                  linkDest='https://www.couchbase.com/products/eventing'
                />
              </div>
              <div>
                <FeatureBlock
                  icon={xdcrIcon}
                  title='XDCR'
                  desc='Synchronize data in real time between data centers using cross data center replication (XDCR).'
                  linkText='About XDCR'
                  linkDest='https://www.couchbase.com/products/xdcr'
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='bg-white'>
        <div className='pt-12 wrapper mx-auto text-center'>
          <h2>How to get started</h2>
          <p className='w-11/12 mx-auto'>
            Couchbase Capella is the easiest and fastest way to get started. You can <a
            href='https://cloud.couchbase.com/sign-up' target='_blank' rel='noreferrer'>sign up for a free
            trial</a> with no credit card
            right now, and get started with your own cluster in a few minutes.
          </p>
        </div>
      </div>


      <div className='py-4 sm:py-12 wrapper mx-auto text-left mb-0 md:mb-6'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-12'>
          <div className='w-11/12 sm:w-full mx-auto'>
            <h3>Try Couchbase Capella Free</h3>
            <p className='italic -mt-2 font-thin'>No credit card required</p>
            <ul>
              <li>
                Try it now and be live in under 3 minutes
              </li>
              <li>
                50GB of storage
              </li>
              <li>
                Try our SQL for JSON query language
              </li>
              <li>
                Sample datasets, tools, and tutorials
              </li>
              <li>
                Flexible JSON docs, key-value speed, full-text search, support for relational design, and more
              </li>
              <li>
                Blazing-fast performance with simple maintenance
              </li>
            </ul>
          </div>
          <div className='flex place-items-center'>
            <a href='https://www.youtube.com/watch?v=c4sVO76nvxw'
               target='_blank'
               rel='noreferrer'
               className='hover:drop-shadow-2xl pl-0 sm:pl-6'
            >
              <img src={capellaVideoThumb} alt='Couchbase Capella Overview' className='' />
            </a>
          </div>
        </div>
        <p className='text-center mt-8 w-11/12 mx-auto'>
          You can also start coding right away in the <a href='https://couchbase.live/' target='_blank'
                                                         rel='noreferrer'>Couchbase
          Playground</a>. No installation is needed, so you can
          execute and modify code samples right in your browser. You can also connect the Playground to your Capella
          trial.
        </p>
      </div>


      <div className='py-12 wrapper mx-auto text-left'>
        <div className='grid grid-cols-1 sm:grid-cols-12 gap-12'>
          <div className='col-span-6 sm:col-span-5 text-center sm:text-left'>
            <h2>FAQs</h2>
            <p>Have questions? We're here to help.</p>
            <img src={faqImage} alt='Frequently Asked Questions' className='pr-6 pb-6 mt-4 w-8/12 sm:w-full mx-auto' />
          </div>
          <div className='mt-0 sm:mt-8 col-span-6 sm:col-span-7'>
            <Accordion allowZeroExpanded={true}>
              {
                FAQs.map((question) => {
                  return (
                    <FAQRow title={question.title}
                            body={question.body}
                            key={question.title}
                    />
                  )
                })
              }

            </Accordion>
          </div>
        </div>
      </div>


      <div className='appdev-red flex flex-row w-full justify-center text-white py-6'>
        <div className='appdev-section mt-6 mb-4 text-center'>
          <h2>Ready to create amazing customer experiences?</h2>
          <p>Get started with Couchbase Capella (DBaaS)</p>
          <a href='https://cloud.couchbase.com/sign-up' target='_blank' rel='noreferrer'
             className='cb-trans-button mt-4'>Try Free</a>
        </div>
      </div>

    </div>
  )
}

export default WhatIsCouchbase

const FeatureBlock = (
  {
    icon, title, desc, linkText, linkDest,
  },
) => {
  return (
    <div className={'flex h-full'}>
      <img src={icon} alt='' className={'-mt-2 w-12 sm:w-14 h-fit'} />
      <div className={'-mt-4 pl-6 pb-4 sm:pb-6 relative'}>
        <h4>{title}</h4>
        <p className={'!text-sm mt-0 sm:-mt-2'}>
          {desc}
        </p>
        <div className='absolute bottom-0'>
          <a href={linkDest}
             target='_blank'
             rel='noreferrer'
             className='!text-sm sm:text-base btn-arrow-right hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>
            {linkText}
          </a>
        </div>

      </div>
    </div>
  )
}

const OverviewCard = (
  {
    icon, title, desc, link1Text, link1Dest, link2Text, link2Dest,
  },
) => {
  return (
    <div className='card-gray drop-shadow-xl'>
      <div className='flex items-center'>
        <img src={icon} alt='' className={'pr-4 pb-1'} />
        <h4 className='flex-wrap w-1/3 pb-1'>{title}</h4>
      </div>

      <hr className='w-24 border border-black-light-thin mb-4 mt-2 sm:mt-1 md:mt-0' />

      <p className='text-left pb-20' dangerouslySetInnerHTML={{ __html: desc }}>

      </p>
      <div className='absolute bottom-6 left-8 w-full text-left'>
        <a href={link1Dest}
           target='_blank'
           rel='noreferrer'
           className='inline-block !text-sm sm:text-base pt-4 btn-arrow-right hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>
          {link1Text}
        </a>
        <br />
        <a href={link2Dest}
           target='_blank'
           rel='noreferrer'
           className='inline-block !text-sm sm:text-base pt-1 btn-arrow-right hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>
          {link2Text}
        </a>
      </div>
    </div>
  )
}

const FAQRow = (
  {
    title, body,
  },
) => {
  return (
    <>
      <AccordionItem className='mb-8'>
        <AccordionItemHeading>
          <AccordionItemButton>
            <div className={'flex'}>
              <div className={'w-11/12'}>
                {title}
              </div>
              <AccordionItemState>
                {(state) => {
                  return (
                    state.expanded ?
                      <FiMinusCircle className='mt-1' />
                      :
                      <FiPlusCircle className='mt-1' />
                  )
                }}
              </AccordionItemState>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p className='!text-md faq-body' dangerouslySetInnerHTML={{ __html: body }} />
        </AccordionItemPanel>
      </AccordionItem>
    </>
  )
}
